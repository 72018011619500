import { Fragment } from 'react';

import Link from 'shopper/components/Link';

import { isLastItem } from 'lib/array';
import { toLandingPageLink } from 'lib/links';

import LANDINGS from 'constants/landings';
import LINKS from 'constants/links';

const PAGES_LINKS_LIST = [
  { name: 'O que é o Promobit?', href: LINKS.PROMOBIT_WHAT_IS },
  { name: 'Black Friday', href: toLandingPageLink('black-friday') },
  { name: 'Comercial', href: LINKS.BUSINESS },
  {
    name: 'Termos de Uso',
    href: toLandingPageLink('termos', LANDINGS.TYPES.INSTITUTIONAL),
  },
  { name: 'Mapa Site', href: LINKS.SITEMAP },
  {
    name: 'Política de Privacidade',
    href: toLandingPageLink(
      'politica-de-privacidade',
      LANDINGS.TYPES.INSTITUTIONAL
    ),
  },
  { name: 'Política de Cookies', href: LINKS.COOKIES_POLICY },
];

const FooterMiddle = () => (
  <div className="flex w-full justify-center border-b-2 border-t-2 border-neutral-high-200 px-4 py-6 dark:border-neutral-low-300 md:py-4">
    <div className="mx-3 flex flex-wrap items-center justify-center text-neutral-low-300 dark:text-neutral-high-100 md:mx-0">
      {PAGES_LINKS_LIST.map(({ name, href }, index, arr) => (
        <Fragment key={name}>
          <Link
            className="mx-2 py-2 xl:py-0"
            href={href}
            size="size2"
            type="secondary"
          >
            {name}
          </Link>
          {!isLastItem(arr, index) && '•'}
        </Fragment>
      ))}
    </div>
  </div>
);

export default FooterMiddle;
